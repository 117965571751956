/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';
import { TariffValue } from 'types';

const OTHER_VALUE = 'Other';
const OCTOPUS_TARIFF = 'Octopus';

export const addOtherValueToEndOfList = (arr: TariffValue[], data?: string): TariffValue[] => {
  if (isEmpty(arr)) return arr;

  const copyArray = [...arr];
  const otherIndex = arr?.findIndex(
    (provider: TariffValue) => provider.label === 'Other' || provider.label === 'Overige'
  );

  if (otherIndex > 0) {
    const otherValue = arr[otherIndex];
    const translatedOtherValue = { ...otherValue, label: data || 'Other' };
    copyArray?.splice(otherIndex, 1);
    copyArray?.push(translatedOtherValue);
  }

  return copyArray;
};

export const sortTariffs = (arr: TariffValue[], otherLabel: string): TariffValue[] => {
  if (isEmpty(arr)) return arr;

  const octopusTariffs = arr.filter((provider) => provider.label.includes(OCTOPUS_TARIFF));
  const otherTariffs = arr.filter(
    (provider) => !provider.label.includes(OCTOPUS_TARIFF) && provider.label !== OTHER_VALUE
  );
  const otherValue = arr.find((provider) => provider.label === OTHER_VALUE);

  otherTariffs.sort((a, b) => a.label.localeCompare(b.label));

  return otherValue
    ? [...octopusTariffs, ...otherTariffs, { ...otherValue, label: otherLabel }]
    : [...octopusTariffs, ...otherTariffs];
};
